




























import InputDefault from "../../../Input/InputDefault.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import BaseForm from "@/components/Base/BaseForm.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        BaseDialog,
        BaseForm,
        InputDefault,
    },
})
export default class AdminUserNew extends Vue {
    @Prop() value!: boolean;

    public isFormValid: boolean = false;
    public formData: any = {};

    get forms() {
        let forms: any[] = [
            {
                text: "Role",
                placeholder: "Pilih salah satu",
                value: "role",
                type: "combobox",
                items: this.filteredRoles,
                itemText: "name",
                itemValue: "id",
                returnObject: true,
                withLabel: true,
                required: true,
            },
            {
                text: "Username",
                placeholder: "Isi username",
                value: "username",
                withLabel: true,
                required: true,
                rules: [
                    (v: any) =>
                        (v || "").indexOf(" ") < 0 ||
                        "Tidak boleh menggunakan spasi",
                ],
            },
        ];

        return forms;
    }

    public passwordForm: any = {
        text: "Password",
        placeholder: "Password akan sama dengan username",
        value: "password",
        withLabel: true,
        required: true,
        disabled: true,
    };

    get roles() {
        return this.$store.state.roles.roles;
    }

    get passwordValue() {
        let password = "";
        if (this.formData && this.formData.username)
            password = this.formData.username;

        return password;
    }

    get filteredRoles() {
        let excludeRole = "PROPOSER";
        let filteredRoles = this.roles.filter(
            (role: any) => role.name !== excludeRole
        );

        return filteredRoles;
    }

    get showDialog() {
        return this.value;
    }
    set showDialog(isActive: boolean) {
        this.$emit("input", isActive);
    }

    async createUser() {
        const body: any = {
            username: this.formData.username,
            password: this.formData.username,
            role: this.formData.role,
        };
        const user = await this.$store.dispatch(
            "users/signUpWithUsernameAndPassword",
            body
        );

        await this.$store.dispatch("users/getAllUser");

        console.log(user);

        this.showDialog = false;
        this.formData = {};
    }

    async mounted() {
        const roles = await this.$store.dispatch("roles/getAllRole");
    }

    @Watch("passwordValue", { immediate: false })
    updatePassword(newValue: string) {
        this.formData.password = this.formData.username;
    }
}
